export const MCU_STATUS = {
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
    REQUESTED: 'REQUESTED',
    ACKNOWLEDGED: 'ACKNOWLEDGED',
    ARRIVED: 'ARRIVED',
    DEPARTED: 'DEPARTED',
    CANCELLED: 'CANCELLED',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
    TRANSFER: 'TRANSFER',
};

export const ACCEPTED_MCU_STATUSES = [MCU_STATUS.ACCEPTED, MCU_STATUS.ARRIVED, MCU_STATUS.DEPARTED];

export const INCIDENT_STATUS = {
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    OPEN: 'OPEN',
    TRANSFER_PENDING: 'TRANSFER_PENDING',
};

export const REFERRAL_STATUS = {
    COMPLETED: 'Completed - Referral',
};

export const TABS = {
    PROFILE: 'PROFILE',
    MOBILE_UNIT: 'MOBILE_UNIT',
};

export const DECLINE_REASON = {
    ON_ANOTHER_CRISIS: 'ON_ANOTHER_CRISIS',
    ON_BREAK: 'ON_BREAK',
    KNOW_THE_INDIVIDUAL: 'KNOW_THE_INDIVIDUAL',
    DISTANCE: 'DISTANCE',
    SHIFT_CHANGE: 'SHIFT_CHANGE',
    CLINICAL: 'CLINICAL',
    OTHER: 'OTHER',
};

export const CANCEL_REQUEST_REASON = {
    DISPATCH_ERROR: 'DISPATCH_ERROR',
    UNTIMELY_RESPONSE: 'UNTIMELY_RESPONSE',
    CLIENT_REQUEST: 'CLIENT_REQUEST',
    THIRD_PARTY_REQUEST: 'THIRD_PARTY_REQUEST',
    ACTIVE_RESCUE: 'ACTIVE_RESCUE',
    LAW_ENFORCEMENT_INTERVENTION: 'LAW_ENFORCEMENT_INTERVENTION',
    OTHER: 'OTHER',
};

export const DECLINE_REASON_DROPDOWN = {
    [DECLINE_REASON.ON_ANOTHER_CRISIS]: 'On another crisis',
    [DECLINE_REASON.ON_BREAK]: 'On break',
    [DECLINE_REASON.KNOW_THE_INDIVIDUAL]: 'Know the individual',
    [DECLINE_REASON.KNOW_THE_INDIVIDUAL]: 'Know the individual',
    [DECLINE_REASON.DISTANCE]: 'Distance',
    [DECLINE_REASON.SHIFT_CHANGE]: 'Shift Change',
    [DECLINE_REASON.CLINICAL]: 'Clinical',
    [DECLINE_REASON.OTHER]: 'Other',
};

export const CANCEL_REQUEST_REASON_DROPDOWN = {
    [CANCEL_REQUEST_REASON.DISPATCH_ERROR]: 'Dispatch Error',
    [CANCEL_REQUEST_REASON.UNTIMELY_RESPONSE]: 'Untimely Response',
    [CANCEL_REQUEST_REASON.CLIENT_REQUEST]: 'Client Request',
    [CANCEL_REQUEST_REASON.THIRD_PARTY_REQUEST]: 'Third Party Request',
    [CANCEL_REQUEST_REASON.ACTIVE_RESCUE]: 'Active Rescue',
    [CANCEL_REQUEST_REASON.LAW_ENFORCEMENT_INTERVENTION]: 'Law Enforcement Intervention',
    [CANCEL_REQUEST_REASON.OTHER]: 'Other',
};

export const DECLINE_REASON_ERRORS = {
    REASON: 'Please select a reason for decline.',
    DESCRIPTION: 'Please enter decline reason.',
};

export const CANCEL_REQUEST_REASON_ERRORS = {
    REASON: 'Please select a reason for canceling.',
    DESCRIPTION: 'Please enter cancel reason.',
};

export const RESPONSE_FORM_ERRORS = {
    DISPOSITION: 'A disposition must be selected',
    EARLY_DEPARTED_TIME: 'Departed time cannot be before the Arrived time',
    FOLLOW_UP_MISSING_FIELDS: 'The follow up section is missing required fields.',
    INVALID_DEPARTED_TIME: 'Invalid Time',
    NOTES: 'Please enter notes for a disposition of "Other"',
    TRANSPORTATION_MISSING_FIELDS: 'Transportation section is missing required fields.',
    TRANSPORTATION_TYPE: 'Transportation Method is required if Transportation Needed is selected',
    TRANSPORT_LOCATION: 'Transport Location is required if Transportation Needed is selected',
    ABUSE_REPORT_MADE: 'This is required if Type of Abuse is selected',
    ABUSE_ASSIST_CALLER: 'This is required if Type of Abuse is selected',
};

export const TRANSPORTATION_MESSAGES = {
    TRANSPORTATION_NEEDED: 'Transportation Needed',
    TRANSPORTATION_TYPE: 'Transport Method',
    TRANSPORTATION_LOCATION: 'Transport Location',
    SELECT_PLACEHOLDER: 'Make a Selection',
};

export const RESPONSE_FORM_NAME = 'ResponseForm';
