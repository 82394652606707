import { INCIDENT_STATUS, MCU_STATUS } from './response';

export const ALL_FILTER = 'ALL';

export const ALL_REGIONS_MESSAGE = 'All Regions';

export const ALL_RESPONDERS_MESSAGE = 'All Responders';

export const ALL_CONTACT_CENTER_MESSAGE = 'All Contact Centers';

export const ALL_CREATED_BY_MESSAGE = 'All Created By';

export const STATUSES = {
    ALL: ALL_FILTER,
    REQUESTING: MCU_STATUS.REQUESTED,
    EN_ROUTE: MCU_STATUS.ACCEPTED,
    IN_PROGRESS: MCU_STATUS.ARRIVED,
    COMPLETED: MCU_STATUS.DEPARTED,
    DECLINED: MCU_STATUS.DECLINED,
    OPEN: MCU_STATUS.OPEN,
    ACKNOWLEDGED: MCU_STATUS.ACKNOWLEDGED,
    CANCELLED_INTAKE: `${INCIDENT_STATUS.CANCELLED}:INTAKE`,
    CANCELLED_DISPATCH: `${MCU_STATUS.CANCELLED}:DISPATCH`,
};

export const STATUSES_MESSAGES = {
    [STATUSES.ALL]: 'All Statuses',
    [STATUSES.REQUESTING]: 'Requesting',
    [STATUSES.EN_ROUTE]: 'En Route',
    [STATUSES.IN_PROGRESS]: 'In Progress',
    [STATUSES.COMPLETED]: 'Completed',
    [STATUSES.DECLINED]: 'Declined',
    [STATUSES.OPEN]: 'Open',
    [STATUSES.ACKNOWLEDGED]: 'Acknowledged',
    [STATUSES.CANCELLED_INTAKE]: 'Cancelled - Contact Form',
    [STATUSES.CANCELLED_DISPATCH]: 'Cancelled - Dispatch',
};

export const SORTABLE_COLS = {
    PATIENT: 'patient',
    REGION: 'region',
    CREATED_ON: 'datetime',
};

export const FILTERS_STORAGE_KEY = 'intakeHistoryFilters';

export const DEFAULT_FILTERS = {
    query: '',
    status: ALL_FILTER,
    regionId: ALL_FILTER,
    responderId: ALL_FILTER,
    startDate: new Date(),
    endDate: new Date(),
};
