import { format, differenceInYears, parse, isValid, addHours, parseISO } from 'date-fns';
import { DECLINE_REASON_DROPDOWN, MCU_STATUS, REFERRAL_STATUS, INCIDENT_STATUS } from '../constants/response';
import { DATE_FORMAT } from 'mobile-crisis/src/constants/datetime';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const METERS_PER_MILE = 1609.34;

const SORT_DIRECTIONS = {
    ASC: 'asc',
    DESC: 'desc',
};

const SERIALIZE_META_KEY = '__meta';

const saveInStorage = (key, data, storage = localStorage) => {
    const dateFields = Object.getOwnPropertyNames(data).filter((key) => data[key] instanceof Date);

    storage.setItem(key, JSON.stringify({ ...data, [SERIALIZE_META_KEY]: dateFields }));
};

const getFromStorage = (key, storage = localStorage, defaultValue = null) => {
    try {
        const data = JSON.parse(storage.getItem(key));

        if (data) {
            const dateFields = data[SERIALIZE_META_KEY];
            dateFields.forEach((key) => (data[key] = new Date(data[key])));
            delete data[SERIALIZE_META_KEY];

            return data;
        }

        return defaultValue;
    } catch (e) {
        return defaultValue;
    }
};

const clearFromStorage = (key, storage = localStorage) => {
    storage.removeItem(key);
};

const noop = () => {};

const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = (phoneNumberString || '').replace(/\D/g, '');
    const match = [cleaned.slice(0, 3), cleaned.slice(3, 6), cleaned.slice(6, 10)];
    const phonetags = ['(', ') ', '-'];

    let result = '';
    let index = 0;
    while (match[index] !== '' && index < match.length) {
        result += phonetags[index] + match[index];
        index++;
    }

    return result;
};

/**
 * Parses a phone number string and formats it to E.164 standard.
 *
 * This function attempts to parse the provided phone number using the 'US'
 * region and checks if the phone number is valid. If valid, it returns
 * the formatted phone number in E.164 format. If invalid, it throws an error
 * and sets an appropriate error message in the component state.
 *
 * @param {string} phoneNumber - The phone number string to be parsed and formatted.
 * @returns {string} - The formatted phone number in E.164 format.
 * @throws {Error} - Throws an error if the phone number is invalid or parsing fails.
 */
const parseAndFormatPhoneNumber = (phoneNumber) => {
    try {
        // Parse and format the phone number to E164 format
        const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');

        // Check if the number is valid
        if (parsedNumber && parsedNumber.isValid()) {
            return parsedNumber.format('E.164');
        } else {
            // Handle invalid phone number
            throw new Error('Invalid phone number format.');
        }
    } catch (error) {
        // throw the error to stop execution
        throw error; // Propagate the error to the calling function
    }
};

const formatTextValue = (text, allowsNewLines = false) => {
    if (!allowsNewLines) {
        return text.replace(/[^ -~]/g, '');
    }

    return text.replace(/[^ -~\n]/g, '');
};

const onlyNumbers = (value, defaultValue = 0) => {
    if (!value && value !== 0) return defaultValue;

    if (typeof value === 'number') return value;

    return value.replace(/\D/, '');
};

const formatZipCode = (value) => {
    return (value || '').replace(/\D/, '').substring(0, 5);
};

const milesToMeters = (miles) => {
    return miles * METERS_PER_MILE;
};

const calcDistanceInMiles = (location1, location2) => {
    const R = 3958.8; // Radius of the Earth in miles
    const rlat1 = location1.lat * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = location2.lat * (Math.PI / 180); // Convert degrees to radians
    const diffLat = rlat2 - rlat1; // Radian difference (latitudes)
    const diffLon = (location2.lng - location1.lng) * (Math.PI / 180); // Radian difference (longitudes)

    return (
        2 *
        R *
        Math.asin(
            Math.sqrt(
                Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
                    Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(diffLon / 2) * Math.sin(diffLon / 2)
            )
        )
    );
};

const capitalizeFirstLetter = (string) => {
    if (!string) return '-';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const parseDate = (dateString, format, referenceDate, options) => {
    let result;

    if (Array.isArray(format)) {
        for (let i = 0; i < format.length; i++) {
            result = parse(dateString, format[i], referenceDate, options);
            if (isValid(result)) break;
        }
    } else {
        result = parse(dateString, format, referenceDate, options);
    }

    return result;
};

/*
    Format dates when sometimes already parsed.
*/
const conditionalISOParsing = (date, customFormat = null) => {
    let parsedDate = parseISO(date);

    if (isValid(parsedDate)) {
        return format(new Date(parsedDate), customFormat ? customFormat : DATE_FORMAT);
    } else if (isValid(date)) {
        return format(new Date(date), customFormat ? customFormat : DATE_FORMAT);
    }
};

/*
    Format dates when sometimes already parsed.
*/
const conditionalISOParsingToNormalizedDateObj = (date, customFormat = null) => {
    let parsedDate = parseISO(date);

    if (isValid(parsedDate)) {
        return new Date(parsedDate);
    } else if (isValid(date)) {
        return new Date(date);
    }
};

const getUTCDate = (date) => {
    if (date && date.endsWith('Z')) {
        return isValid(new Date(date)) ? new Date(date) : null;
    }

    return date && isValid(new Date(`${date.split(' ').join('T')}Z`))
        ? new Date(`${date.split(' ').join('T')}Z`)
        : null;
};

const isMobileScreen = () => window.matchMedia('(max-width: 767px)').matches;

const validatePhoneNumber = (phoneNumber) => !phoneNumber || phoneNumber.replace(/\D/g, '').length === 10;

const createGoogleMapsDrivingLink = (street, city, state, zip) => {
    const destination = `${street},+${city},+${state}+${zip}`.replaceAll(' ', '+');
    return `https://www.google.com/maps/dir/?api=1&destination=${destination}&travelmode=driving`;
};

export const getDisplayStatus = (intakeStatus, mcuStatus, reason, disposition, referral) => {
    let formatted = {
        label: null,
        details: null,
    };

    if (intakeStatus === INCIDENT_STATUS.CANCELLED) {
        formatted.label = window.intakeFormVersionTwoEnabled ? 'Contact Form Cancelled' : 'Intake Cancelled';
        return formatted;
    }
    if ((intakeStatus === INCIDENT_STATUS.OPEN || !intakeStatus) && !mcuStatus && !referral) {
        formatted.label = 'Open';
        return formatted;
    }
    if (
        (!intakeStatus || intakeStatus) &&
        (mcuStatus === MCU_STATUS.REQUESTED || mcuStatus === MCU_STATUS.ACKNOWLEDGED) &&
        !referral
    ) {
        formatted.label = 'Requesting';
        return formatted;
    }
    if (mcuStatus === MCU_STATUS.CANCELLED && (!intakeStatus || intakeStatus) && !referral) {
        formatted.label = 'Cancelled - Dispatch';
        return formatted;
    }

    if (mcuStatus === MCU_STATUS.DECLINED && (!intakeStatus || intakeStatus) && !referral) {
        formatted.label = 'Declined';
        formatted.details = DECLINE_REASON_DROPDOWN[reason];
        return formatted;
    }
    if (mcuStatus === MCU_STATUS.ACCEPTED && (!intakeStatus || intakeStatus) && !referral) {
        formatted.label = 'En Route';
        return formatted;
    }
    if (mcuStatus === MCU_STATUS.ARRIVED && (!intakeStatus || intakeStatus) && !referral) {
        formatted.label = 'In Progress';
        return formatted;
    }
    if ((intakeStatus === INCIDENT_STATUS.COMPLETED && referral) || referral) {
        formatted.label = REFERRAL_STATUS.COMPLETED;
        return formatted;
    }
    if (mcuStatus === MCU_STATUS.DEPARTED && !referral) {
        formatted.label = 'Completed';
        formatted.details = disposition;
        return formatted;
    }

    return formatted;
};

export {
    noop,
    conditionalISOParsing,
    conditionalISOParsingToNormalizedDateObj,
    formatPhoneNumber,
    parseAndFormatPhoneNumber,
    formatTextValue,
    onlyNumbers,
    formatZipCode,
    milesToMeters,
    calcDistanceInMiles,
    capitalizeFirstLetter,
    parseDate,
    getUTCDate,
    isMobileScreen,
    validatePhoneNumber,
    createGoogleMapsDrivingLink,
    saveInStorage,
    getFromStorage,
    clearFromStorage,
    SORT_DIRECTIONS,
};

export default {
    noop,
    formatPhoneNumber,
    parseAndFormatPhoneNumber,
    formatTextValue,
    onlyNumbers,
    formatZipCode,
    milesToMeters,
    conditionalISOParsing,
    conditionalISOParsingToNormalizedDateObj,
    calcDistanceInMiles,
    capitalizeFirstLetter,
    parseDate,
    getUTCDate,
    isMobileScreen,
    validatePhoneNumber,
    createGoogleMapsDrivingLink,
    saveInStorage,
    getFromStorage,
    clearFromStorage,
    SORT_DIRECTIONS,
};
