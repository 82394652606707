/*
 *'use strict';
 */

import 'core-js/es/object';
import 'core-js/es/typed-array';
import 'core-js/es/object';
import 'core-js/es/set';
import { createRoot } from 'react-dom/client';

import React from 'react';
import ReactDom from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { Amplify, Auth } from 'aws-amplify';
import Cookies from 'universal-cookie';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import querystring from 'querystring';
import getAmplifyConfiguration from './amplifyConfiguration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { parseISO, differenceInDays } from 'date-fns';
import { parseAndFormatPhoneNumber } from '../mobile-crisis/src/utils/utils';

import { trackEvent } from '@shared/helpers';

import './Login.scss';

window.createReactRoot = createRoot;

let auth = getAmplifyConfiguration();

Amplify.configure({
    Auth: auth,
});

const MFATypes = {
    SMS: true, // if SMS enabled in your user pool
    TOTP: false, // if TOTP enabled in your user pool
    Optional: false, // if MFA is set to optional in your user pool
};

class Login extends React.Component {
    constructor(props) {
        super(props);

        // Declaring reviewDaysThreshold as a class property
        this.reviewDaysThreshold = window.userContactInfoUpdateCheckDays;
        this.mfaCodeMaxAttempts = 5;
        this.failedLoginAttemptWarningThreshold = 4; // Trigger warning on the 4th failed attempt
        this.failedLoginWaningMessage =
            "The credentials you've entered are incorrect. Please ensure your credentials are correct, or reset your password if necessary.";
        this.failedLoginLockoutMessage = `Your account is temporarily locked due to multiple failed login attempts. Reset your password or try again later.`;

        this.state = {
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            newPassword: '',
            user: null,
            phoneNumber: '',
            daysSinceLastUpdate: null,
            contactInfoLastUpdated: null,
            mfaCodeValue: '',
            phoneVerificationCode: '',
            verificationCode: '',
            challengeName: '',
            loginSubmit: [],
            errorMessage: '',
            message: '',
            searchingSSO: false,
            showMFASetup: false,
            showMFAChallenge: false,
            showLoginForm: true,
            showSSOLoginForm: false,
            showForgotPassword: false,
            showRequireNewPassword: false,
            showSigningInScreen: false,
            showReviewContactInfoScreen: false,
            showPhoneNumberVerificationScreen: false,
            showPhoneUpdateSuccessScreen: false,
            showGoBackLoginButton: true,
            redirectUri: '',
            ssoRedirectUri: '',
            continueToLogin: false,
            resendCodeClicked: false,
            /*
             * Password validation comes from Cognito Requirements:
             * https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
             */
            password_validation: {
                button_disabled: true,
                length: {
                    valid: false,
                    regex: /^.{8,}$/,
                },
                capital: {
                    valid: false,
                    regex: /[A-Z]/,
                },
                number: {
                    valid: false,
                    regex: /[0-9]/,
                },
                special_character: {
                    valid: false,
                    regex: /[= + - ^ $ * . \[ \] { } ( ) ? " ! @ # % & \/ \ , > < ' : ; | _ ~ `]/,
                },
            },
        };
    }

    componentDidMount() {
        let parsed = querystring.parse(window.location.hash.slice(1));

        if ('logout' in parsed) {
            Auth.signOut();
            window.location.href = route('logout');
            return;
        }

        const cookies = new Cookies();
        let cookiePrefix = `CognitoIdentityServiceProvider.SSOLogin.`;

        //when we come from SSO url is appended with access_token fragment
        if (parsed.hasOwnProperty('access_token')) {
            let cookie_settings = Auth._storage;
            delete cookie_settings.expires;
            cookie_settings.maxAge = parsed.expires_in;

            cookies.set(cookiePrefix + `accessToken`, parsed.access_token, cookie_settings);
            cookies.set(cookiePrefix + `idToken`, parsed.id_token, cookie_settings);
            cookies.set('crossAppSigninNoRedirect', 'true', cookie_settings);

            this.sendTokenToBackend(parsed.access_token);

            return;
        }

        if (cookies.get(cookiePrefix + `accessToken`)) {
            this.sendTokenToBackend(cookies.get(cookiePrefix + `accessToken`));

            return;
        }

        Auth.currentSession()
            .then((data) => {
                this.sendTokenToBackend(data.accessToken.jwtToken, { autoLoginCheck: true });
            })
            .catch((error) => {});
    }

    /**
     * Fetches authenticated user details and calculates days since the last contact info update.
     * Sends a POST request to retrieve user details based on the email.
     * Calculates the number of days since the last contact information update.
     *
     * @param {string} email - The email address of the user.
     * @returns {Promise<{contactInfoLastUpdated: string|null, daysSinceLastUpdate: number|null}>|null}
     * - `contactInfoLastUpdated`: Timestamp of the last update (or null if unavailable).
     * - `daysSinceLastUpdate`: Number of days since the last update (or null if not applicable).
     * - Returns `null` if the request fails or is unsuccessful.
     *
     */
    fetchUserDetails = async (email) => {
        const axiosConfig = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
            },
            withCredentials: true,
        };

        try {
            const response = await axios.post(route('login.getAuthenticatedUserDetails'), { email }, axiosConfig);

            if (response.data.status === 'SUCCESS') {
                const { contact_info_updated_at } = response.data.data;

                // Calculate days since last update
                let daysSinceLastUpdate = null;

                if (contact_info_updated_at) {
                    const lastUpdatedDate = parseISO(contact_info_updated_at);
                    const currentDate = new Date();
                    daysSinceLastUpdate = differenceInDays(currentDate, lastUpdatedDate);
                }

                return { contactInfoLastUpdated: contact_info_updated_at, daysSinceLastUpdate };
            } else {
                console.error('Error fetching user details:', response.data.message);
                return null;
            }
        } catch (error) {
            console.error('Fetch error:', error.response?.data?.message || error.message);
            return null;
        }
    };

    /**
     * Sends a POST request to update user details in the backend for when the contact info is outdated.
     *
     * @param {Object} userData - User details to update, including:
     *   email, first_name, last_name, phone_number, contact_info_updated_at.
     *
     * @throws {Error} Re-throws errors for handling in the calling function.
     */
    updateUserDetails = async (userData) => {
        try {
            await axios.post(route('login.updateUserDetailsPostAuthVerification'), userData, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                },
            });
        } catch (error) {
            console.error('Error updating user details:', error);
            throw error; // Re-throw to allow further handling in the calling function
        }
    };

    checkSSO = (e) => {
        let data = new FormData();
        this.setState({ searchingSSO: true });

        let username = this.state.username.toLowerCase();

        if (username === '') {
            this.setState(
                {
                    searchingSSO: false,
                },
                () => {
                    this.displayScreen('showLoginForm');
                }
            );

            return;
        }

        fetch(route('login.check-sso-domain', { username: username }), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'same-origin',
        }).then((response) => {
            if (!response.ok) {
                return; //We do not want to throw errors if this call fails
            }

            response.json().then((data) => {
                if (data.status === 'SUCCESS') {
                    data = data.data;
                    this.setState({
                        showLoginForm: false,
                        showSSOLoginForm: true,
                        ssoRedirectUri: data.redirectUri,
                        searchingSSO: false,
                    });
                } else {
                    if (data.error_constant === 'ORGANIZATION_NO_DIRECT_ACCOUNT_LOGIN') {
                        this.setState(
                            {
                                errorMessage: data.data.message,
                                searchingSSO: false,
                            },
                            () => {
                                this.displayScreen();
                            }
                        );
                    } else {
                        this.setState(
                            {
                                ssoRedirectUri: '',
                                searchingSSO: false,
                            },
                            () => {
                                this.displayScreen('showLoginForm');
                            }
                        );
                    }
                }
            });
        });
    };

    handlePhoneNumberChange = (e) => {
        this.setState({
            phoneNumber: this.normalizePhoneNumber(e.target.value),
            errorMessage: '',
        });
    };

    handlePhoneVerificationCodeChange = (e) => {
        this.setState({
            phoneVerificationCode: e.target.value,
            errorMessage: '',
        });
    };

    handleMFACodeChange = (e) => {
        this.setState({
            mfaCodeValue: e.target.value,
            message: '',
            errorMessage: '',
        });
    };

    handleUsernameChange = (e) => {
        this.setState({
            username: e.target.value,
            searchingSSO: true,
        });
    };

    handlePasswordChange = (e) => {
        this.setState({
            password: e.target.value,
            searchingSSO: false,
        });
    };

    /**
     * Handles the MFA challenge for the user.
     * This method confirms the MFA code entered by the user and processes the sign-in.
     * It also tracks failed MFA attempts using window.localStorage to persist the count
     * across page refreshes and initiates a password reset if the maximum attempts are exceeded.
     *
     * @param {Object} e - The event object from the form submission.
     */
    mfaChallenge = async (e) => {
        e.preventDefault();

        const { mfaCodeValue, user } = this.state;
        const mfaType = 'SMS_MFA';

        // Validate if MFA code is provided
        if (!mfaCodeValue) {
            let err = { code: 'MissingPhoneVerificationCode' };
            this.processCaughtError(err);
            return;
        }

        const username = user?.username || 'unknown_user';
        let failedMfaAttempts = JSON.parse(window.localStorage.getItem('failedMfaAttempts')) || {};

        try {
            // Attempt to confirm the sign-in
            const confirmedUser = await Auth.confirmSignIn(user, mfaCodeValue, mfaType);

            // Remove failed attempts for this username
            this.resetFailedAttempts('mfa', username);

            // Mark the challenge as passed
            user.challengeName = 'SMS_PASSED';

            this.processSignInStep(confirmedUser);
        } catch (err) {
            // Increment failed attempts for this username
            failedMfaAttempts[username] = (failedMfaAttempts[username] || 0) + 1;
            window.localStorage.setItem('failedMfaAttempts', JSON.stringify(failedMfaAttempts));

            if (failedMfaAttempts[username] >= this.mfaCodeMaxAttempts) {
                // Handle specific errors with tailored messages
                this.setState({
                    errorMessage:
                        'Too many invalid attempts. Please try again in a few seconds or click on Forgot Password to reset your account.',
                    showGoBackLoginButton: false,
                });
            } else {
                // Handle specific errors with tailored messages
                if (err.code === 'NotAuthorizedException') {
                    this.setState({
                        errorMessage:
                            'Too many invalid attempts. Please try again in a few seconds or click on Forgot Password to reset your account.',
                        showGoBackLoginButton: false,
                    });
                } else {
                    // Display an error message for invalid code
                    this.setState({
                        errorMessage:
                            'Invalid code. Please try again in a few seconds or click on Forgot Password to reset your account.',
                        showGoBackLoginButton: false,
                    });
                }
            }
        }
    };

    /**
     * Initiates a password reset process for the user.
     * This method is called when the user fails to input the correct MFA code
     * more than the allowed maximum number of attempts.
     *
     * @async
     * @function
     */
    initiatePasswordReset = async () => {
        const { username } = this.state;

        try {
            // Start the password reset process
            await Auth.forgotPassword(username);

            this.displayScreen('showForgotPassword');

            // Provide feedback to the user
            this.setState({
                message: 'Password reset initiated. Check your email to reset your password.',
                errorMessage: '', // Clear any previous error messages
            });
        } catch (err) {
            // Handle specific errors with tailored messages
            const errorMessage =
                err.code === 'LimitExceededException'
                    ? 'Too many password reset attempts. Please try again after 24 hours or contact Support.'
                    : 'Failed to initiate password reset. Please try again later.';

            this.setState({ errorMessage, message: '' }); // Clear success messages
        }
    };

    changePasswordRequired = async (e) => {
        e.preventDefault();
        await Auth.completeNewPassword(
            this.state.user, // the Cognito User Object
            this.state.newPassword // the new password
        )
            .then((data) =>
                this.setState({ message: 'Password updated successfully. Please login with new password.' }, () => {
                    this.displayScreen('showLoginForm');
                })
            )
            .catch((err) => this.processCaughtError(err));
    };

    changePasswordInput = (e) => {
        let length = RegExp(this.state.password_validation.length.regex);
        let special_character = RegExp(this.state.password_validation.special_character.regex);
        let capital = RegExp(this.state.password_validation.capital.regex);
        let number = RegExp(this.state.password_validation.number.regex);

        let password_validation = this.state.password_validation;

        password_validation.capital.valid = capital.test(e.target.value);
        password_validation.special_character.valid = special_character.test(e.target.value);
        password_validation.length.valid = length.test(e.target.value);
        password_validation.number.valid = number.test(e.target.value);

        if (
            password_validation.capital.valid === true &&
            password_validation.special_character.valid === true &&
            password_validation.length.valid === true &&
            password_validation.number.valid === true
        ) {
            password_validation.button_disabled = false;
        } else {
            password_validation.button_disabled = true;
        }

        this.setState({
            newPassword: e.target.value,
            password_validation: password_validation,
        });
    };

    changeVerificationCode = (e) => {
        e.preventDefault();
        this.setState({
            verificationCode: e.target.value,
        });
    };

    changePassword = async (e) => {
        e.preventDefault();
        try {
            // Await the forgotPasswordSubmit call and handle result
            const data = await Auth.forgotPasswordSubmit(
                this.state.username.toLowerCase(),
                this.state.verificationCode,
                this.state.newPassword
            );

            // If successful, update the message and switch to login form
            this.setState(
                {
                    message: 'Password updated successfully. Please login with new password.',
                },
                () => {
                    this.displayScreen('showLoginForm');
                }
            );
        } catch (err) {
            // Handle specific errors with tailored messages
            if (err.code === 'LimitExceededException') {
                this.setState({
                    errorMessage:
                        'Too many password reset attempts. Please try again after 24 hours or Contact Support.',
                    showGoBackLoginButton: false,
                });
            } else {
                this.setState({
                    errorMessage: err.message,
                    showGoBackLoginButton: false,
                });
            }
        }
    };

    sendTokenToBackend = async (jwt, additional = null) => {
        this.displayScreen('showSigningInScreen');

        try {
            let data = new FormData();
            data.append('accessToken', jwt);

            let csrf = document.querySelector('meta[name="csrf-token"]').content;
            data.append('csrfToken', csrf);

            // Check if additional data exists, and append each key-value pair
            if (additional) {
                Object.entries(additional).forEach(([key, value]) => {
                    data.append(key, value);
                });
            }

            fetch(route('signin-verify'), {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                body: data,
                credentials: 'same-origin',
            }).then(async (response) => {
                // try {
                //     // Try to fetch the authenticated user
                //     const user = await Auth.currentAuthenticatedUser();
                //     const username = user?.username || 'unknown_user';
                //     const attributes = await Auth.userAttributes(user);
                //     const rawPhoneNumber = attributes.find((attr) => attr.Name === 'phone_number')?.Value;
                //     const firstName = attributes.find((attr) => attr.Name === 'given_name')?.Value;
                //     const lastName = attributes.find((attr) => attr.Name === 'family_name')?.Value;
                //     const email = attributes.find((attr) => attr.Name === 'email')?.Value;

                //     // Normalize the phone number to a standard format "+1 <digits>"
                //     const normalizedPhoneNumber = this.normalizePhoneNumber(rawPhoneNumber || '');

                //     const userDetails = await this.fetchUserDetails(email);

                //     if (userDetails) {
                //         const { contactInfoLastUpdated, daysSinceLastUpdate } = userDetails;

                //         if (
                //             !this.state.continueToLogin &&
                //             (!contactInfoLastUpdated || daysSinceLastUpdate > this.reviewDaysThreshold)
                //         ) {
                //             // Show contact review prompt if info is outdated
                //             this.displayScreen('showReviewContactInfoScreen');
                //             this.setState({
                //                 daysSinceLastUpdate,
                //                 phoneNumber: normalizedPhoneNumber || '',
                //                 firstName: firstName || '',
                //                 lastName: lastName || '',
                //             });
                //             return;
                //         }
                //     }

                //     // Remove failed attempts for this username
                //     this.resetFailedAttempts('login', username);
                //     this.resetFailedAttempts('mfa', username);
                // } catch (error) {
                //     console.warn('User not authenticated or error fetching attributes:', error);
                // }

                if (!response.ok) {
                    response.json().then((data) => {
                        if (data.error_constant === 'CSRF_EXPIRED') {
                            window.location.href = window.location.href;
                        }
                    });
                    this.setState({
                        showSigningInScreen: false,
                        showLoginForm: true,
                        showSSOLoginForm: false,
                        searchingSSO: false,
                    });
                    throw Error(response.statusText);
                }

                response.json().then((data) => {
                    if (data.status === 'SUCCESS') {
                        data = data.data;

                        this.setState({
                            redirectUri: data.redirectUri,
                        });

                        //remove redirect cookie if this is the last step in the signin
                        if (data.redirectUri != route('login.account_selector')) {
                            const cookies = new Cookies();
                            cookies.remove('crossAppSigninNoRedirect', Auth._storage);
                        }

                        trackEvent(
                            'Login',
                            {},
                            (eventTrackingResponse) => (window.location.href = this.state.redirectUri)
                        );
                    } else {
                        this.setState({
                            showSigningInScreen: false,
                            errorMessage: data.data.message,
                        });
                    }
                });
            });
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * Handles the sign-in process and manages various challenges (e.g., MFA, new password, phone verification).
     * @param {Object|null} user - The Cognito user object returned after a sign-in attempt. If null, the function exits early.
     * @returns {Promise<void>} - Processes the sign-in step and updates the state or screen accordingly.
     */
    async processSignInStep(user = null) {
        if (!user) {
            console.error('No user provided for sign-in process.');
            return false;
        }

        const { challengeName, username } = user;

        // Set the challengeName in the state
        this.setState({ challengeName });

        const challengeScreens = {
            SMS_MFA: 'showPhoneUpdateSuccessScreen',
            SOFTWARE_TOKEN_MFA: 'showPhoneUpdateSuccessScreen',
            NEW_PASSWORD_REQUIRED: 'showRequireNewPassword',
            MFA_SETUP: 'showMFASetup',
        };

        try {
            // Special handling for SMS_MFA challenge
            if (challengeName === 'SMS_MFA') {
                // Retrieve failed attempts for this username
                const failedMfaAttemptsData = JSON.parse(window.localStorage.getItem('failedMfaAttempts')) || {};
                const failedMfaAttempts = failedMfaAttemptsData[username] || 0;

                // Show the "Phone Update Success Screen" for SMS_MFA challenge
                this.displayScreen(challengeScreens[challengeName]);
                return;
            }

            // Default behavior for other challenge types
            if (challengeScreens[challengeName]) {
                this.displayScreen(challengeScreens[challengeName]);
                return;
            }

            // const attributes = await Auth.userAttributes(user);

            // Check phone verification status
            // if (!this.isPhoneVerified(attributes)) {
            //     this.redirectToPhoneVerification(attributes);
            //     return;
            // }

            // If all checks pass, complete the sign-in process
            const jwt = user.signInUserSession.accessToken.getJwtToken();
            this.completeSignIn(jwt, user);
        } catch (err) {
            console.error('Error processing sign-in step:', err);

            // Display a general error screen
            this.displayScreen('errorScreen');
        }
    }

    async completeSignIn(jwt, user) {
        //set cookie here without the '%' symbol which causes an error
        const cookies = new Cookies();
        let cookie_settings = Auth._storage;
        delete cookie_settings.expires;

        let cookiePrefix = `CognitoIdentityServiceProvider.`;

        cookies.set(cookiePrefix + `idToken`, user.signInUserSession.idToken.getJwtToken(), cookie_settings);
        cookies.set('crossAppSigninNoRedirect', 'true', cookie_settings);

        this.sendTokenToBackend(jwt);
    }

    /**
     * Checks if the user's phone number is verified based on the user attributes.
     * @param {Array<Object>} attributes - An array of user attributes from Cognito, each with `Name` and `Value` properties.
     * @returns {boolean} - Returns true if the phone number exists and is verified; otherwise, false.
     */
    isPhoneVerified(attributes) {
        const rawPhoneNumber = attributes.find((attr) => attr.Name === 'phone_number')?.Value;
        const isPhoneVerified = attributes.find((attr) => attr.Name === 'phone_number_verified')?.Value === 'true';
        return rawPhoneNumber && isPhoneVerified;
    }

    /**
     * Redirects the user to the phone verification screen and sets the phone number in the state.
     * @param {Array<Object>} attributes - An array of user attributes from Cognito, each with `Name` and `Value` properties.
     */
    redirectToPhoneVerification(attributes) {
        const rawPhoneNumber = attributes.find((attr) => attr.Name === 'phone_number')?.Value;
        const normalizedPhoneNumber = this.normalizePhoneNumber(rawPhoneNumber || '');

        this.displayScreen('showPhoneNumberVerificationScreen');
        this.setState({
            phoneNumber: normalizedPhoneNumber || '',
        });
    }

    updatePhoneNumber = async () => {
        const { phoneNumber } = this.state;

        if (!phoneNumber) {
            // Trigger the error handler for missing fields
            let err = { code: 'MissingPhoneNumber' };
            this.processCaughtError(err);
            return;
        }

        try {
            // Format the phone number using the utility function
            let parsedPhoneNumber = parseAndFormatPhoneNumber(phoneNumber);

            // Retrieve the current authenticated user
            const user = await Auth.currentAuthenticatedUser();

            // Update the user's attributes in Cognito
            await Auth.updateUserAttributes(user, { phone_number: parsedPhoneNumber });

            // Trigger verification for the phone number
            await Auth.verifyCurrentUserAttribute('phone_number');

            // Transition to the success screen
            this.displayScreen('showPhoneUpdateSuccessScreen');
        } catch (error) {
            // Handle invalid phone number format
            if (error.message === 'Invalid phone number format.') {
                this.setState({ errorMessage: error.message, showGoBackLoginButton: false });
                return; // Stop further execution if the phone number is invalid
            }

            // Handle errors during verification
            if (error.code === 'CodeDeliveryFailureException') {
                this.setState({
                    errorMessage: 'Failed to send verification code. Please try again.',
                    showGoBackLoginButton: false,
                });
                return;
            }

            console.error('Error updating phone number:', error);
            this.setState({
                errorMessage: 'An unexpected error occurred. Please try again.',
                showGoBackLoginButton: false,
            });
        }
    };

    verifyPhoneNumber = async () => {
        const { phoneVerificationCode } = this.state;

        if (!phoneVerificationCode) {
            // Trigger the error handler for missing fields
            let err = { code: 'MissingPhoneVerificationCode' };
            this.processCaughtError(err);
            return;
        }

        try {
            await Auth.verifyCurrentUserAttributeSubmit('phone_number', phoneVerificationCode);

            this.displayScreen();

            this.setState({
                message: 'Phone number verified successfully!',
            });
            this.displayScreen('showLoginForm');
        } catch (error) {
            // Handle errors during verification
            if (error.code === 'CodeMismatchException') {
                this.setState({
                    errorMessage: 'Invalid verification code provided, please try again.',
                    showGoBackLoginButton: false,
                });
                return;
            }
            this.setState({
                errorMessage: 'An unexpected error occurred. Please try again.',
                showGoBackLoginButton: false,
            });
        }
    };

    async handleUpdateContactInfo() {
        const { firstName, lastName, phoneNumber } = this.state;

        // Validate input fields
        if (!firstName || !lastName || !phoneNumber) {
            // Trigger the error handler for missing fields
            let err = { code: 'MissingContactInfo' };
            this.processCaughtError(err);
            return;
        }

        try {
            // Get the current user's session
            const user = await Auth.currentAuthenticatedUser();

            // Check if the phone number needs to be updated or verified
            const currentAttributes = await Auth.userAttributes(user);
            const currentPhoneNumber = currentAttributes.find((attr) => attr.Name === 'phone_number')?.Value;

            // Format the phone number using the utility function to a standard E.164 format
            const parsedPhoneNumber = parseAndFormatPhoneNumber(phoneNumber);

            // Normalize the phone number to a standard format "+1 <digits>"
            const normalizedPhoneNumber = this.normalizePhoneNumber(phoneNumber || '');

            // Generate the current timestamp
            const currentTimestamp = new Date().toISOString();

            // Proceed with updating user details in the backend
            const userData = {
                username: user.attributes.email,
                contact_info_updated_at: currentTimestamp,
            };

            // Update state to reflect the new data in UI fields
            this.setState({
                firstName,
                lastName,
                phoneNumber: normalizedPhoneNumber,
            });

            // Call the helper function for backend update contact_info_updated_at field
            await this.updateUserDetails(userData);

            // Update the user's attributes in Cognito
            await Auth.updateUserAttributes(user, {
                phone_number: parsedPhoneNumber,
                given_name: firstName,
                family_name: lastName,
            });

            if (parsedPhoneNumber !== currentPhoneNumber) {
                // Show verification code input if the phone number has changed
                this.displayScreen('showPhoneUpdateSuccessScreen');
            } else {
                // No change in phone number, just show success message
                this.setState({
                    message: 'Information has been successfully updated!',
                });

                this.displayScreen('showLoginForm');
            }
        } catch (error) {
            if (error.message === 'Invalid phone number format.') {
                this.setState({ errorMessage: error.message });
                return; // Stop further execution if the phone number is invalid
            }
            console.error('Error updating user information:', error);
        }
    }

    processCaughtError(err) {
        let message = '';

        if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
            message = 'Username and Password do not match!';
        } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
            this.displayScreen('showForgotPassword');
            return;
            //   message = 'Your password must be reset. Please click <a href="/forgot">here</a> to reset it.'
        } else if (err.code === 'NotAuthorizedException') {
            // Delegate handling to the reusable function
            message = this.handleFailedLoginAttemptError(err);
            // Use the returned message
            this.setState({
                showGoBackLoginButton: true,
            });
        } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'Username and Password do not match!';
        } else if (err.code === 'CodeMismatchException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'Code provided is invalid. Please request a code again.';
        } else if (err.code === 'ExpiredCodeException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'Code provided is invalid. Please request a code again.';
        } else if (err.code === 'LimitExceededException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'You have exceeded the number of times to login. Please try again after a few minutes.';
        } else if (err.code === 'MissingRequirements') {
            //The error happens when the username/email or password field is completely empty.
            message = 'Both username and password are required.';
        } else if (err.code === 'MissingContactInfo') {
            // The error happens when first name, last name, or phone number fields are completely empty.
            message = 'First name, last name, and phone number are required to update contact information.';
            this.setState({ showGoBackLoginButton: false });
        } else if (err.code === 'MissingPhoneNumber') {
            // The error happens when first name, last name, or phone number fields are completely empty.
            message = 'Phone number is required to get a verification code.';
            this.setState({ showGoBackLoginButton: false });
        } else if (err.code === 'MissingPhoneVerificationCode') {
            // The error happens when first name, last name, or phone number fields are completely empty.
            message = 'Verification failed. Please check the code and try again.';
            this.setState({ showGoBackLoginButton: false });
        } else {
            message = 'There was an error';
            console.log(err);
        }
        this.setState(
            {
                errorMessage: message,
            },
            () => {
                if (this.state.showGoBackLoginButton) {
                    this.displayScreen();
                }
            }
        );
    }

    displayScreen(form = null) {
        let screens = {
            showMFASetup: false,
            showMFAChallenge: false,
            showLoginForm: false,
            showForgotPassword: false,
            showSigningInScreen: false,
            showSSOLoginForm: false,
            showRequireNewPassword: false,
            showReviewContactInfoScreen: false,
            showPhoneNumberVerificationScreen: false,
            showPhoneUpdateSuccessScreen: false,
        };

        if (form === 'showSigningInScreen') {
            screens.message = '';
            screens.errorMessage = '';
        }

        if (form) {
            screens[form] = true;
        }
        this.setState(screens);
    }

    signIn = async (e) => {
        e.preventDefault();

        const hostname = window && window.location && window.location.hostname;

        if (
            this.state.showSSOLoginForm === false &&
            (this.state.username === null ||
                this.state.username === '' ||
                this.state.password === null ||
                this.state.password === '')
        ) {
            //If the username/email or password fields are empty, throw an error, otherwise continue.
            let err = [];
            err.code = 'MissingRequirements';
            this.processCaughtError(err);

            return;
        }

        if (this.state.ssoRedirectUri === '') {
            Auth.signOut();
            let context_host = hostname;
            if (context_host.indexOf('hbu.co') != -1) {
                context_host = 'api-' + context_host;
            } else if (context_host.indexOf('prep.openbeds.net') != -1) {
                //if accessed via prep.openbeds.net needs to set context host to api-state.ob.prep.hbu.co
                //extract out the state, it is the first substring before the "."
                context_host = 'api-' + context_host.split('.')[0] + '.ob.prep.hbu.co';
            } else {
                context_host = 'internal-' + context_host;
            }
            await Auth.signIn(this.state.username.toLowerCase(), this.state.password, { context_host: context_host })
                .then((user) => {
                    this.setState({ user: user }, () => {
                        this.processSignInStep(this.state.user);
                    });
                })
                .catch((err) => {
                    this.processCaughtError(err);
                });
        } else {
            window.location.href = this.state.ssoRedirectUri;
        }
    };

    /**
     * Handles failed login attempts by tracking the number of attempts, storing them in sessionStorage,
     * and determining the appropriate error message based on specific conditions.
     *
     * @param {Object} err - The error object containing the error message to evaluate.
     * @returns {string} - The appropriate error message to display.
     */
    handleFailedLoginAttemptError = (err) => {
        const username = this.state.username.toLowerCase();

        // Get current failed attempts from sessionStorage
        let failedLoginAttempts = JSON.parse(window.sessionStorage.getItem('failedLoginAttempts')) || {};

        // Increment failed attempts for this username
        failedLoginAttempts[username] = (failedLoginAttempts[username] || 0) + 1;
        sessionStorage.setItem('failedLoginAttempts', JSON.stringify(failedLoginAttempts));

        // Determine error message based on specific conditions
        if (err.message === 'Incorrect username or password.') {
            if (failedLoginAttempts[username] === this.failedLoginAttemptWarningThreshold) {
                return this.failedLoginWaningMessage;
            } else {
                return 'Username and Password do not match!';
            }
        } else if (err.message === 'Password attempts exceeded') {
            return this.failedLoginLockoutMessage;
        } else {
            return 'Username and Password do not match!';
        }
    };

    handleUpdateNumberLink = (e) => {
        e.preventDefault();
        this.displayScreen('showPhoneNumberVerificationScreen');
    };

    handleContinueSignInLink = async (e) => {
        e.preventDefault();

        try {
            // Set state to indicate that the user is continuing to login
            this.setState({ continueToLogin: true });

            // Retrieve the current authenticated user
            const user = await Auth.currentAuthenticatedUser();

            const jwt = user.signInUserSession.accessToken.getJwtToken();

            // Send the token to the backend
            this.sendTokenToBackend(jwt);
        } catch (err) {
            console.error('Error during sign-in continuation:', err);
        }
    };

    handleResendMFACodeToPhone = async (e) => {
        e.preventDefault();

        const { username, password } = this.state;

        if (!username || !password) {
            this.setState({
                errorMessage: 'Unable to resend the verification code. Please log in again.',
            });
            return;
        }

        try {
            // Using SigIn Method to resend the MFA code
            const user = await Auth.signIn(username, password);

            // Handle the case when the user is prompted for SMS_MFA
            if (user.challengeName === 'SMS_MFA') {
                this.setState({
                    user,
                    challengeName: 'SMS_MFA',
                    message: 'A new verification code has been sent to your registered phone number.',
                    errorMessage: '',
                    mfaCodeValue: '',
                    resendCodeClicked: true, // Set this to true after sending the code
                });

                // Remove failed attempts for this username
                this.resetFailedAttempts('mfa', username);
            } else {
                // Handle unexpected challenges
                this.setState({
                    errorMessage: 'Unexpected challenge. Please try logging in again.',
                });
            }
        } catch (error) {
            console.error('Error resending MFA code:', error);
            // Set error message in state if the request fails
            this.setState({
                errorMessage: 'Unable to resend the verification code. Please try again later.',
                message: '',
            });
        }
    };

    handleResendVerificationCodeToEmail = async (e) => {
        e.preventDefault();

        const { username } = this.state;

        try {
            // Attempt to send the verification code
            await Auth.forgotPassword(username.toLowerCase());

            // Optionally, display success feedback or change UI state
            this.setState({
                message: 'Verification code sent to your email. Please check your inbox.',
                errorMessage: '', // Clear any previous error messages
            });
        } catch (err) {
            // Handle specific errors with tailored messages
            const errorMessage =
                err.code === 'LimitExceededException'
                    ? 'Too many password reset attempts. Please try again after 24 hours or Contact Support.'
                    : 'Failed to send verification code. Please try again later.';

            this.setState({
                errorMessage,
                message: '',
                showGoBackLoginButton: false,
            }); // Clear success messages
        }
    };

    /**
     * This function returns how many days it has been since the user last updated their contact information.
     * If `daysSinceLastUpdate` has been set, it will return that value to display it to the user.
     * Otherwise, it defaults to the `reviewDaysThreshold` to indicate the maximum allowed days without an update.
     *
     * @returns {number} - The number of days to show the user for how long it's been since their last update.
     */
    daysSincelLastContactInfoUpdate = () => {
        return this.state.daysSinceLastUpdate !== null ? this.state.daysSinceLastUpdate : this.reviewDaysThreshold;
    };

    /**
     * Normalizes a phone number to a standard format with the US country code to the format "+1 <digits>".
     */
    normalizePhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return '';
        const digits = phoneNumber.replace(/[^\d]/g, '').replace(/^1/, '').slice(0, 10);
        return `+1 ${digits}`;
    };

    /**
     * Resets failed attempts for a given type and username from localStorage|sessionStorage.
     * This is used when a user successfully logs in or passes the MFA challenge.
     *
     * @param {string} type - The type of failed attempts to reset (e.g., 'login' or 'mfa').
     * @param {string} username - The username whose failed attempts should be removed.
     */
    resetFailedAttempts = (type, username) => {
        if (!username || !type) return;

        // Determine the storage key and storage type based on the type
        const storageKey = type === 'login' ? 'failedLoginAttempts' : 'failedMfaAttempts';
        const storage = type === 'login' ? window.sessionStorage : window.localStorage;

        // Retrieve failed attempts from storage
        let failedAttempts = JSON.parse(storage.getItem(storageKey)) || {};

        // Check if there are any failed attempts for this username
        if (failedAttempts[username]) {
            delete failedAttempts[username];

            // If the failedAttempts object is empty, remove it from storage
            if (Object.keys(failedAttempts).length === 0) {
                storage.removeItem(storageKey);
            } else {
                // Otherwise, update storage with the new state
                storage.setItem(storageKey, JSON.stringify(failedAttempts));
            }
        }
    };

    render() {
        return (
            <>
                {this.state.message !== '' ? (
                    <>
                        <div className="alert alert-success">{this.state.message}</div>
                    </>
                ) : (
                    <></>
                )}
                {this.state.errorMessage !== '' ? (
                    <>
                        <div className="alert alert-danger">{this.state.errorMessage}</div>
                        {this.state.showGoBackLoginButton && (
                            <div className="d-grid gap-2">
                                <Button
                                    id="btnReturnToLogin"
                                    size="lg"
                                    type="submit"
                                    onClick={() => {
                                        this.setState({
                                            showLoginForm: true,
                                            errorMessage: '',
                                        });
                                    }}
                                >
                                    Go Back to Login Screen
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    <></>
                )}
                {this.state.showPhoneNumberVerificationScreen && (
                    <div className="phone-update-container">
                        <h3 className="text-center">Update Your Phone Number</h3>
                        <p>
                            Your phone number is missing or unverified. To continue using your account securely, please
                            provide a valid phone number. After updating, you will receive a verification code to
                            confirm your phone number.
                        </p>
                        <p>
                            This step is essential for protecting your account and ensuring seamless access to
                            <strong> OpenBeds</strong>.
                        </p>
                        <Form>
                            <Form.Group size="lg" className="form-group mb-3" controlId="inputPhoneNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    value={this.state.phoneNumber}
                                    onChange={this.handlePhoneNumberChange}
                                    placeholder="Enter your phone number"
                                    maxLength={13} // "+1" + 10 digits
                                />
                            </Form.Group>
                            <div className="d-grid gap-2">
                                <Button size="lg" onClick={this.updatePhoneNumber}>
                                    Update Phone Number
                                </Button>
                            </div>
                        </Form>
                        {this.state.challengeName !== 'SMS_MFA' && (
                            <>
                                <p className="mt-3">
                                    Don't want to update now?{' '}
                                    <a href="#" onClick={this.handleContinueSignInLink}>
                                        Continue to Login
                                    </a>
                                </p>
                            </>
                        )}
                    </div>
                )}
                {this.state.showPhoneUpdateSuccessScreen && (
                    <div className="verification-code-container">
                        <center>
                            <h3>
                                {this.state.challengeName === 'SMS_MFA'
                                    ? 'Verify your Account'
                                    : 'Verify Your Phone Number'}
                            </h3>
                        </center>
                        {this.state.challengeName === 'SMS_MFA' ? (
                            <p>
                                For your security, verify your account through Multi-Factor Authentication (MFA). A
                                verification code has been sent to your registered contact method. Please enter the code
                                below to complete the login process. This code will only be
                                <strong> valid for 5 minutes.</strong>
                            </p>
                        ) : (
                            <p>
                                A verification code has been sent to your phone number. Please enter the code below to
                                confirm and secure your account.
                            </p>
                        )}
                        <Form>
                            {this.state.challengeName !== 'SMS_MFA' && (
                                <Form.Group size="lg" className="form-group mb-3" controlId="inputPhoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="tel" value={this.state.phoneNumber} disabled />
                                </Form.Group>
                            )}
                            <Form.Group size="lg" className="form-group mb-3" controlId="inputVerificationCode">
                                <Form.Label>Verification Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={
                                        this.state.challengeName === 'SMS_MFA'
                                            ? this.state.mfaCodeValue
                                            : this.state.phoneVerificationCode
                                    }
                                    onChange={
                                        this.state.challengeName === 'SMS_MFA'
                                            ? this.handleMFACodeChange
                                            : this.handlePhoneVerificationCodeChange
                                    }
                                    placeholder="Enter the verification code"
                                />
                            </Form.Group>
                            <div className="d-grid gap-2">
                                <Button
                                    size="lg"
                                    onClick={
                                        this.state.challengeName === 'SMS_MFA'
                                            ? this.mfaChallenge
                                            : this.verifyPhoneNumber
                                    }
                                >
                                    Verify {this.state.challengeName === 'SMS_MFA' ? 'Login' : 'Phone Number'}
                                </Button>
                            </div>
                        </Form>
                        <p className="mt-3">
                            Didn't receive the code?{' '}
                            {this.state.challengeName !== 'SMS_MFA' && (
                                <>
                                    <a href="#" onClick={this.handleUpdateNumberLink}>
                                        Verify Number
                                    </a>{' '}
                                    or{' '}
                                </>
                            )}
                            {this.state.challengeName === 'SMS_MFA' ? (
                                this.state.resendCodeClicked ? (
                                    <span style={{ color: '#337ab7', fontWeight: 'bold' }}>Code Sent!</span>
                                ) : (
                                    <a href="#" onClick={this.handleResendMFACodeToPhone}>
                                        Resend Code
                                    </a>
                                )
                            ) : (
                                <a href="#" onClick={this.handleContinueSignInLink}>
                                    Continue to Login
                                </a>
                            )}
                        </p>
                    </div>
                )}
                {this.state.showLoginForm && (
                    <Form>
                        <Form.Group size="lg" controlId="inputUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                autoFocus
                                type="email"
                                value={this.state.username}
                                onChange={this.handleUsernameChange}
                                onBlur={this.checkSSO}
                            />
                        </Form.Group>
                        <Form.Group size="lg" className="form-group mb-3" controlId="inputPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                            />
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button
                                id="btnLogin"
                                size="lg"
                                type="submit"
                                onClick={this.signIn}
                                disabled={this.state.searchingSSO}
                            >
                                Login
                            </Button>
                        </div>
                    </Form>
                )}
                {this.state.showReviewContactInfoScreen && (
                    <div className="review-info-container">
                        <h3 className="text-center">Time to Update Your Contact Information</h3>
                        <p>
                            We've noticed you haven't reviewed or updated your contact information in the last{' '}
                            <strong>{this.daysSincelLastContactInfoUpdate()}</strong> days. Keeping your details current
                            is crucial for ensuring the security of your account and facilitating effective
                            communication.
                        </p>
                        <p>
                            Please take a moment to verify and update your contact information. This step is essential
                            for maintaining access to your account and using <strong>OpenBeds</strong> without
                            interruptions.
                        </p>
                        <Form>
                            <Form.Group size="lg" controlId="inputFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={this.state.firstName}
                                    onChange={(e) => this.setState({ firstName: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="inputLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={this.state.lastName}
                                    onChange={(e) => this.setState({ lastName: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group size="lg" className="form-group mb-3" controlId="inputPhoneNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    value={this.state.phoneNumber}
                                    onChange={this.handlePhoneNumberChange}
                                    placeholder="Enter your phone number"
                                    maxLength={13} // "+1" + 10 digits
                                />
                            </Form.Group>
                            <div className="d-grid gap-2">
                                <Button
                                    size="lg"
                                    onClick={() => {
                                        this.handleUpdateContactInfo();
                                    }}
                                >
                                    Update Contact Information
                                </Button>
                            </div>
                        </Form>
                        <p className="mt-3">
                            Don't want to update now?{' '}
                            <a href="#" onClick={this.handleContinueSignInLink}>
                                Continue to Login
                            </a>
                        </p>
                    </div>
                )}
                {this.state.showSSOLoginForm ? (
                    <Form>
                        <Form.Group size="lg" className="form-group mb-3" controlId="inputUsernameSso">
                            <Form.Label>Username</Form.Label>

                            <Form.Control
                                type="email"
                                value={this.state.username}
                                onChange={this.handleUsernameChange}
                                onBlur={this.checkSSO}
                            />
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button
                                id="btnLoginSso"
                                size="lg"
                                type="submit"
                                onClick={this.signIn}
                                disabled={this.state.searchingSSO}
                            >
                                Login Through Your Organization
                            </Button>
                        </div>
                    </Form>
                ) : (
                    <></>
                )}
                {this.state.showRequireNewPassword ? (
                    <>
                        <b>You are required to change your password</b>
                        <br />
                        Please provide a new password below:
                        <br />
                        <br />
                        <Form>
                            <Form.Group size="lg" className="form-group" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={this.newPassword}
                                    onChange={this.changePasswordInput}
                                />
                            </Form.Group>
                            <div>
                                Passwords are required to meet the following requirements:
                                <ul style={{ 'list-style-type': 'none' }}>
                                    <li>
                                        {this.state.password_validation.length.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 8 characters.
                                    </li>
                                    <li>
                                        {this.state.password_validation.capital.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 capitalized letter.
                                    </li>
                                    <li>
                                        {this.state.password_validation.number.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 number.
                                    </li>
                                    <li>
                                        {this.state.password_validation.special_character.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 special character (!#$ etc.)
                                    </li>
                                </ul>
                            </div>
                            <div className="d-grid gap-2">
                                <Button
                                    size="lg"
                                    type="submit"
                                    onClick={this.changePasswordRequired}
                                    disabled={this.state.password_validation.button_disabled}
                                >
                                    Change Password
                                </Button>
                            </div>
                        </Form>
                    </>
                ) : (
                    <></>
                )}
                {this.state.showForgotPassword && (
                    <div className="change-forgot-password-container">
                        <h3 className="text-center">Change your password</h3>
                        <p>
                            Please enter the verification code sent to your email and provide a new password to reset
                            your password.
                        </p>
                        <Form>
                            <Form.Group size="lg" className="form-group mb-3" controlId="username">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="email"
                                    value={this.state.username}
                                    onChange={this.handleUsernameChange}
                                />
                            </Form.Group>
                            <Form.Group size="lg" className="form-group mb-3" controlId="password">
                                <Form.Label>Verification Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={this.verificationCode}
                                    onChange={this.changeVerificationCode}
                                />
                            </Form.Group>
                            <Form.Group size="lg" className="form-group mb-3" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={this.newPassword}
                                    onChange={this.changePasswordInput}
                                />
                            </Form.Group>
                            <div>
                                Passwords are required to meet the following requirements:
                                <ul style={{ 'list-style-type': 'none' }}>
                                    <li>
                                        {this.state.password_validation.length.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 8 characters.
                                    </li>
                                    <li>
                                        {this.state.password_validation.capital.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 capitalized letter.
                                    </li>
                                    <li>
                                        {this.state.password_validation.number.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 number.
                                    </li>
                                    <li>
                                        {this.state.password_validation.special_character.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 special character (!#$ etc.)
                                    </li>
                                </ul>
                            </div>
                            <div className="d-grid gap-2">
                                <Button
                                    size="lg"
                                    type="submit"
                                    onClick={this.changePassword}
                                    disabled={this.state.password_validation.button_disabled}
                                >
                                    Change Password
                                </Button>
                            </div>
                        </Form>
                        <p className="mt-3">
                            Didn’t get the code?{' '}
                            <a href="#" onClick={this.handleResendVerificationCodeToEmail}>
                                Resend code
                            </a>
                        </p>
                    </div>
                )}
                {this.state.showSigningInScreen ? (
                    <>
                        <div className="alert alert-success">Logging In...</div>
                        <br />
                        <br />
                    </>
                ) : (
                    ''
                )}
            </>
        );
    }
}

export default Login;
